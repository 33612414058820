$background-color: #3cb054;
$accent-color: #dff0d8;
$text-color: white;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif !important;
  color: $text-color !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.vertical-timeline::before {
  background: $background-color !important;
}

a,
a:hover,
a:focus,
a:active {
  outline: none;
  color: $text-color !important;
  text-decoration: none !important;
}

.m0 {
  margin: 0 !important;
}

h1 {
  text-align: center;
  font-weight: 900 !important;
  font-size: 2rem !important;
  @media (min-width: 992px) {
    font-size: 2.5rem !important;
  }
}

.ppc {
  &-paragraph {
    font-size: 1rem;
    text-align: center;
    @media (min-width: 412px) {
      font-size: 1.1rem;
    }
    @media (min-width: 576px) {
      font-size: 1.2rem;
    }
  }
  &-background {
    background-color: $background-color;
    background-image: url("../stars_as\ group.png");
    background-position: top;
    background-size: auto;
    &__timeLine {
      background-color: $background-color;
    }
  }
  &-hero {
    height: 100vh;
    min-height: 1000px;
  }
  &-arrow {
    &_down {
      background-color: $background-color;
      border-radius: 2rem;
      position: absolute;
      bottom: 10px;
      right: 10px;
    }
  }

  &-card {
    padding: 3rem;
    position: relative;
    border: 2px solid white;
    border-radius: 5px;
    max-width: 80vw;
    background-color: $background-color;

    @media (min-width: 576px) {
      max-width: 70vw;
    }

    @media (min-width: 992px) {
      max-width: 60vw;
      min-width: 50vw;
    }

    @media (min-width: 1200px) {
      max-width: 50vw;
      min-width: 40vw;
    }

    &_error {
      color: rgb(158, 22, 22);
    }
    &_icon {
      position: absolute;
      top: -30px;
      padding: 0.4rem;
      left: -30px;
      width: 60px;
      background: rgb(60, 176, 84) none repeat scroll 0% 0%;
      height: 60px;
      border: 2px white solid;
      border-radius: 100;
      border-radius: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &-santa {
    animation: shake 0.8s;
    &:hover {
      animation: shake 0.8s infinite;
    }
  }

  &-buy {
    border: 2px solid white;
    max-height: 55px;
    min-height: 55px;
    border-radius: 5px;
    padding: 0.7rem;
  }

  &-wishes {
    @import url("https://fonts.googleapis.com/css2?family=Lobster");
    font-family: "Lobster", sans-serif !important;
    font-size: 2rem;
    letter-spacing: 0.1rem;
    text-align: center;

    @media (min-width: 576px) {
      font-size: 3.5rem;
    }
    @media (min-width: 992px) {
      letter-spacing: 0.4rem;
    }
  }

  &-timeline {
    &_title {
      margin-left: 0.5rem;
    }
    &_text {
      font-size: 1.5rem;
      font-style: italic;
    }
    &_source {
      font-size: 0.8rem;
    }
    &_background {
      background-color: $accent-color;
    }
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid $accent-color;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $accent-color transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
